<template>
  <b-modal
    v-model="visible"
    size="xl"
    cancel-variant="outline-secondary"
    :ok-title="$t('Сохранить')"
    no-close-on-backdrop
    :cancel-title="$t('Отмена')"
    centered
    :title="title"
    @ok.prevent="save"
  >
    <validation-observer
      ref="form"
      #default="{ invalid }"
    >
      <b-form class="auth-login-form mt-2">
        <el-row
          class="mb-2"
          :gutter="20"
        >
          <el-col :span="6">
            <b-form-group
              :label="$t('Категория')"
              label-for="category_id"
            >
              <v-select
                id="category_id"
                v-model="form.p0_category_id"
                :options="categoriesLevel0"
                :reduce="(option) => option.id"
                class="w-100"
                style="width: 100%"
                placeholder="Категория"
                label="name_uz"
                @input="parent0change($event)"
              />
            </b-form-group>
          </el-col>
          <el-col
            v-if="form.p0_category_id"
            :span="6"
          >
            <b-form-group
              :label="$t('Подкатегория')"
              label-for="category_id"
            >
              <v-select
                id="category_id"
                v-model="form.p1_category_id"
                :options="categoriesLevel1"
                :reduce="(option) => option.id"
                class="w-100"
                label="name_uz"
                placeholder="Подкатегория"
                @input="parent1change($event)"
              />
            </b-form-group>
          </el-col>
          <el-col
            v-if="form.p1_category_id"
            :span="6"
          >
            <b-form-group
              :label="$t('Номенклатура')"
              label-for="category_id"
            >
              <v-select
                id="category_id"
                v-model="form.p2_category_id"
                :options="categoriesLevel2"
                :reduce="(option) => option.id"
                placeholder="Номенклатура"
                class="w-100"
                label="name_uz"
                @input="parent2change($event)"
              />
            </b-form-group>
          </el-col>
          <el-col
            v-if="form.p2_category_id"
            :span="6"
          >
            <b-form-group
              :label="$t('Mahsulot nomi')"
              label-for="category_id"
            >
              <v-select
                id="category_id"
                v-model="form.category_id"
                :options="categoriesLevel3"
                :reduce="(option) => option.id"
                label="name_uz"
              />
            </b-form-group>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <b-form-group
              label="Boshqa mahsulot"
              label-for="investor_id"
            >
              <b-form-checkbox
                v-model="isAnotherProduct"
                :value="true"
                :unchecked-value="false"
                @input="oncheckboxChanged"
              >
                Boshqa mahsulot
              </b-form-checkbox>
            </b-form-group>
          </el-col>
          <el-col
            v-if="isAnotherProduct"
            :span="5"
          >
            <b-form-group
              :label="$t('Mahsulot nomi')"
              label-for="category_id"
            >
              <validation-provider
                #default="{ errors }"
                name="other_product_name"
                rules="required"
              >
                <b-form-input
                  id="other_product_name"
                  v-model="form.other_product_name"
                  :state="errors.length > 3 ? false : null"
                  name="other_product_name"
                  placeholder="Mahsulot nomini kiriting"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </el-col>
        </el-row>

        <b-row>
          <b-col cols="4">
            <b-form-group
              :label="$t('Фамилия')"
              label-for="lastname"
            >
              <validation-provider
                #default="{ errors }"
                name="lastname"
                rules="required"
              >
                <b-form-input
                  id="lastname"
                  v-model="form.lastname"
                  :state="errors.length > 3 ? false : null"
                  name="lastname"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="$t('Имя')"
              label-for="firstname"
            >
              <validation-provider
                #default="{ errors }"
                name="firstname"
                rules="required"
              >
                <b-form-input
                  id="firstname"
                  v-model="form.firstname"
                  :state="errors.length > 3 ? false : null"
                  name="firstname"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="$t('Отчества')"
              label-for="middlename"
            >
              <validation-provider
                #default="{ errors }"
                name="middlename"
                rules="required"
              >
                <b-form-input
                  id="middlename"
                  v-model="form.middlename"
                  :state="errors.length > 3 ? false : null"
                  name="middlename"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="$t('Введите телефон')"
              label-for="v-phone"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Введите телефон')"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    +998
                  </b-input-group-prepend>

                  <b-form-input
                    id="v-phone"
                    v-model="form.phone"
                    v-mask="'#########'"
                    name="v-phone"
                    :state="errors.length > 3 ? false : null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="$t('Zaklad miqdori')"
              label-for="amount"
            >
              <cleave
                id="amount"
                v-model="form.zaklad_amount"
                class="form-control"
                :options="cleaveOption"
                placeholder="0"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="$t('Yetqazish sanasi')"
              label-for="end_date"
            >
              <x-date-picker
                id="end_date"
                v-model="form.delivery_date"
                name="end_date"
                :placeholder="$t('Дата')"
              />
            </b-form-group>
          </b-col>

          <b-col cols="9">
            <b-form-group
              :label="$t('Izoh')"
              label-for="address"
            >
              <validation-provider
                #default="{ errors }"
                name="address"
                rules="required"
              >
                <b-form-textarea
                  id="address"
                  v-model="form.comment"
                  :state="errors.length > 3 ? false : null"
                  name="name"
                  placeholder="Izoh"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions } from 'vuex'
import { clearObject } from '@/utils'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'

export default {
  name: 'Create',
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    Cleave,
  },
  data() {
    return {
      form: {
        p0_category_id: null,
        p1_category_id: null,
        p2_category_id: null,
        category_id: null,
        other_product_name: null,
        zaklad_amount: null,
        delivery_date: null,
        lastname: '',
        firstname: '',
        middlename: '',
        phone: null,
        comment: null,
      },
      cleaveOption: { numeral: true, numeralThousandsGroupStyle: 'thousand' },
      isAnotherProduct: false,
      categoriesLevel0: [],
      categoriesLevel1: [],
      categoriesLevel2: [],
      categoriesLevel3: [],
      title: '',
      visible: false,
      required,
    }
  },
  computed: {},
  watch: {
    visible(newVal) {
      if (!newVal) {
        setTimeout(() => {
          clearObject(this.form)
        }, 200)
      }
    },
  },
  mounted() {
    this.title = this.$t("Taklif qo'shish")
    this.getCategoriesByLevel(0)
    this.$root.$on('add', data => {
      this.visible = true
    })
  },
  methods: {
    async save() {
      const valid = await this.validationForm()
      if (valid) {
        this.method(this.form)
          .then(res => {
            showToast(
              'success',
              this.$t('Успешно сохранено'),
              'CheckCircleIcon',
            )
            this.$emit('onSuccess')
            this.visible = false
          })
          .catch(err => {
            if (err.status === 422) {
              this.$refs.form.setErrors(err.data.errors)
            }
            showToast('danger', this.$t('Ошибка'), 'XIcon')
          })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    edit(item) {
      console.log(item)
      this.getItem(item.id).then(res => {
        this.form.id = res.data.id
        this.form.p0_category_id = res.data.p0_category_id
        this.form.p1_category_id = res.data.p1_category_id
        this.form.p2_category_id = res.data.p2_category_id
        this.form.category_id = res.data.category_id
        this.form.other_product_name = res.data.other_product_name
        this.form.lastname = res.data.lastname
        this.form.firstname = res.data.firstname
        this.form.middlename = res.data.middlename
        this.form.phone = res.data.phone
        this.form.comment = res.data.comment
        this.form.zaklad_amount = res.data.zaklad_amount
        this.form.delivery_date = res.data.delivery_date
        this.visible = true
      })
    },
    method(data) {
      if (this.form.id) return this.updateItem(data)
      return this.storeItem(data)
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    oncheckboxChanged() {
      if (this.isAnotherProduct) {
        this.form.category_id = null
      } else {
        this.form.other_product_name = null
      }
    },
    //
    async parent0change(event) {
      this.form.p1_category_id = null
      this.form.p2_category_id = null
      this.form.page = 1
      await this.getCategoriesByLevel(1, event)
    },
    parent1change(event) {
      this.form.parent2_category_id = null
      this.form.page = 1
      this.getCategoriesByLevel(2, event)
    },
    parent2change(event) {
      this.getCategoriesByLevel(3, event)
    },
    getCategoriesByLevel(level, parentId = null) {
      this.loading = true
      this.getCategories({ level, parent_id: parentId, per_page: 9999 })
        .then(res => {
          this[`categoriesLevel${level}`] = res.data.data
        })
        .finally(() => {
          // this.getItems()
          this.loading = false
        })
    },
    ...mapActions({
      getCategories: 'productCategory/index',
      storeItem: 'resource/storeSuggestion',
      getItem: 'resource/getSuggestion',
      updateItem: 'resource/updateSuggestion',
    }),
  },
}
</script>

<style scoped></style>
