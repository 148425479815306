<template>
  <div>
    <save
      ref="saveForm"
      @onSuccess="getItems"
    />
    <b-form-checkbox
      v-model="filterModel.zakladable"
      class="mb-2"
      :value="1"
      :unchecked-value="null"
      @input="getItems"
    >
      Zaklad berilganlarni ko'rsatish
    </b-form-checkbox>
    <b-overlay :show="loading">
      <good-table-column-search
        :items="items.data"
        :columns="columns"
        :total="items.total"
        :page="page"
        :has-show="false"
        model="suggestion"
        :filter="filterModel"
        @getItems="getItems"
        @add="$refs.saveForm.visible = true"
        @edit="(item) => $refs.saveForm.edit(item)"
        @delete="destroy"
        @onPageChange="(p) => (page = p)"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          {{ props }}
        </template>
      </good-table-column-search>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showToast } from '@/utils/toast'
import GoodTableColumnSearch from '@/views/table/vue-good-table/GoodTableColumnSearch.vue'
import Save from './save.vue'

export default {
  name: 'Index',
  components: {
    GoodTableColumnSearch,
    Save,
  },
  data() {
    return {
      page: 1,
      loading: false,
      roles: [],
      filterModel: {
        per_page: 15,
        firstname: null,
        lastname: null,
        address: null,
        zakladable: null,
        relations: 'createdUser|p0Category|p1Category|p2Category|category',
      },
      items: [],
      columns: [
        {
          label: '#',
          field: 'row_number',
        },
        {
          label: this.$t('Фамилия'),
          field: 'lastname',
          showField: true,
        },
        {
          label: this.$t('Имя'),
          field: 'firstname',
          showField: true,
        },
        {
          label: this.$t('Отчества'),
          field: 'middlename',
          showField: true,
        },
        {
          label: this.$t('Категория'),
          field: 'p0_category.name_uz',
        },
        {
          label: this.$t('Подкатегория'),
          field: 'p1_category.name_uz',
        },
        {
          label: this.$t('Номенклатура'),
          field: 'p2_category.name_uz',
        },
        {
          label: this.$t('Mahsulot nomi'),
          field: 'p0_category.name_uz',
        },
        {
          label: this.$t('Boshqa mahsulot'),
          field: 'other_product_name',
        },
        {
          label: this.$t('Telefon'),
          field: 'phone',
        },
        {
          label: this.$t('Izoh'),
          field: 'comment',
        },
        {
          label: this.$t('Zaklad miqdori'),
          field: 'zaklad_amount',
        },
        {
          label: this.$t('Yetqazish sanasi'),
          field: 'delivery_date',
          isDate: true,
        },
        {
          label: this.$t('Кто зарегистрирован'),
          field: 'created_user.name',
        },
        {
          label: this.$t('Дата'),
          field: 'created_at',
          isDate: true,
        },
        {
          label: this.$t('Действия'),
          field: 'action',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({}),
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.per_page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
  },
  mounted() {
    this.getItems()
  },
  methods: {
    ...mapActions({
      getItemsAction: 'resource/getSuggestions',
      destroyAction: 'resource/destroySuggestion',
    }),
    async getItems() {
      this.loading = true
      await this.getItemsAction({ ...this.filterModel, page: this.page }).then(
        res => {
          this.items = res.data
        },
      )
      this.loading = false
    },
    nameByLocal(nameuz, nameru) {
      if (this.$i18n.locale == 'uz') {
        return nameuz
      }
      return nameru
    },
    destroy(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('Вы действительно хотите удалить?'), {
          title: this.$t('Подтвердите'),
          size: 'sm',
          variant: 'warning',
          okVariant: 'primary',
          okTitle: this.$t('Да'),
          cancelTitle: this.$t('Нет'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.destroyAction(id)
              .then(res => {
                showToast('success', this.$t('Успешно удалено'))
                this.getItems()
              })
              .catch(() => {
                showToast('success', this.$t('Успешно удалено'))
              })
          }
        })
    },
    mapCols(filterName, res) {
      this.columns.map(item => {
        if (item.field === filterName) {
          item.filterOptions.filterDropdownItems = res.data.data.map(i => {
            i.value = i.id
            i.text = i.name
            return i
          })
        }
        // if (item.field === 'status') {
        //   item.filterOptions.filterDropdownItems = [
        //     { value: 1, text: this.$t('Активный') },
        //     { value: 0, text: this.$t('Неактивный') },
        //   ]
        // }
      })
    },
  },
}
</script>

<style scoped></style>
